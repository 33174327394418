import React from 'react'
import './ProjectCard.css'
import { Link } from 'react-router-dom';

function ProjectCard(proj) {
  return (
    <div className='ProjectCard'>
      <Link to={`/project/${proj.Id}`} style={{textDecoration:'none'}}>
        <img className='Card-Thumbnail' src={proj.Thumbnail ? proj.Thumbnail : '/TestThumbnail.png'} alt="Thumbnail" />
        <div className="card-title">{proj.Title}</div>
        <div className="card-description" >{proj.MiniDescription}</div>
        <div className='tags-container' style={{display: 'flex'}}>
        {
          proj.Tags.map(tag => (
            <div className='card-tags' style={{backgroundColor: tag == "Game" ? "#4651b1" : "" }}>{tag}</div>
          ))
        }
        </div>
      </Link>
    </div>
  )
}

export default ProjectCard
