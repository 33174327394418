export const projects = {
  UniverseOfTrials: {
    id: "UniverseOfTrials",
    type: ['Game', 'PC', "Steam", "Multiplayer"],
    Title: "Universe Of Trials",
    MiniDescription: 'Universe of Trials is a multiplayer climbing game with fun modes. Race to the top, dodge hazards in "Floor is Lava," survive in "Falling Floor," or outlast everyone in "Last to Leave." Compete, climb, and conquer the leaderboards!',
    Description: `<div><div style="color:black;">Universe of Trials is a multiplayer climbing game with fun modes. Race to the top, dodge hazards in "Floor is Lava," survive in "Falling Floor," or outlast everyone in "Last to Leave." Compete, climb, and conquer the leaderboards!</div>
    <br><br> <div style="color:#256196; font-weight:bold">Key Features:</div><br>
Multiplayer Competition: Challenge friends or players worldwide in various high-stakes modes.
<br><br>
Multiple Game Modes: From classic races to "Floor is Lava," "Falling Floor," and "Last to Leave," each mode keeps the gameplay fresh.
<br><br>
Dynamic Environments: Climb through diverse levels with unique themes and hazards.
<br><br>
Skill-Based Gameplay: Master precise movements and strategies to overcome obstacles and opponents.
<br><br>
No Checkpoints: Every fall tests your resolve in this high-intensity climb.
<br><br>
Simple Controls: Easy to pick up, challenging to master, perfect for casual and competitive play.
<br><br>
Leaderboards: Track your progress and compete for the top spot across multiple modes.
<br><br>
Are you ready to conquer the "Universe of Trials"? Rise above the competition and claim your victory!</div>`,
    Thumbnail: "https://shared.cloudflare.steamstatic.com/store_item_assets/steam/apps/3214730/ss_061a48397925db39088677ddcb6d300af03274ea.600x338.jpg?t=1729439560",
    DemoUrl: "https://store.steampowered.com/app/3214730/Universe_Of_Trials/"
  },
  InfinitoBattlegrounds: {
    id: "1",
    type: ['Game', 'Multiplayer', 'Mobile'],
    Title: "Infinito Battlegrounds",
    MiniDescription: 'Infinito battlegrounds is an offline third person shooter game.',
    Description: "Infinito battlegrounds is an offline third person shooter game.",
    Thumbnail: "https://img.itch.zone/aW1nLzExNzcwNTAyLnBuZw==/original/ixo%2F7V.png",
    DemoUrl: "https://rohitpatidar.itch.io/infinitobattlegrounds"
  },
  GenesisMobile: {
    id: "2",
    type: ['Game', "Single player", 'Mobile'],
    Title: "Genesis Mobile",
    MiniDescription: "Genesis Mobile is an open world game with lots of things to enjoy in game.",
    Description: "Genesis Mobile is an open world game with lots of things to enjoy in game.",
    Thumbnail: "https://cdn.wccftech.com/wp-content/uploads/2014/11/GTA-V.jpg",
    DemoUrl: ""
  },
  /*ArticleSummarizer: {
    id: "3",
    type: 'Website',
    Title: "Article Summarizer || Ai Based",
    Description: "With the help of this website you can summarize any article by just providing it's link.",
    Thumbnail: "https://rohitpatidar.com/P5S.png",
    DemoUrl: ""
  },
  EthereumTransaction: {
    id: "4",
    type: 'Website',
    Title: "Ethereum Transaction || Blockchain Technology",
    Description: "With the help of this website you can send ethereum cryptocurrency to anyone.",
    Thumbnail: "https://rohitpatidar.com/P4S.png",
    DemoUrl: ""
  },
  DigitalMarketingLandingPage: {
    id: "5",
    type: 'Website',
    Title: "Digital Marketing || Landing Page",
    Description: "Landing page for digital marketing company and other services like web development.",
    Thumbnail: "https://rohitpatidar.com/P3S.png",
    DemoUrl: ""
  }*/
};
