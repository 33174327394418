import React from 'react'
import './Footer.css'
import { Link } from 'react-router-dom'

function Footer() {
  return (
    <div className="footer-overlay">
    <div className='Footer gradient'>
    <div style={{marginBottom:'8px', fontWeight:'500'}}>Social Handles</div>
        <div className="social-medias-container">
            <a className="social-media-icon" href='https://www.instagram.com/rohit.patidar7/' target='_blank'>
              <img src="/insta.webp" alt="I" style={{padding:'6px', filter:'invert()'}} />
            </a>
            <a className="social-media-icon" href='https://x.com/RohitPatidar57' target='_blank'>
              <img src="/xlogo.webp" alt="I" style={{filter:'invert()'}} />
            </a>
            <a className="social-media-icon" href='https://www.youtube.com/@RohitPatidar.' target='_blank'>
              <img src="/youtube.webp" alt="I" style={{padding:'1px', filter:'invert()'}} />
            </a>
        </div>
        <div className="copyright-text">© 2024, rohitpatidar.com | all rights reserved</div>
    </div>
    </div>
  )
}

export default Footer
