import React, { useState, useRef } from 'react'
import { Canvas, useFrame, useLoader } from '@react-three/fiber'
import { Points, PointMaterial } from '@react-three/drei'
import * as random from 'maath/random/dist/maath-random.esm'
import { TextureLoader } from 'three/src/loaders/TextureLoader'
// import RLetter from './r'
import * as THREE from 'three';

export default function StarsBack() {
  return (
    <div style={{position:'fixed', height:'100vh', width:'100vw', zIndex:'-1'}}>
      <Canvas camera={{ position: [0, 0, 1] }}>
        <Stars />
        {/* <Earth /> */}
      <directionalLight intensity={3} position={[-3, 1, 5]} castShadow></directionalLight>
      {/* <ambientLight intensity={1} position={[0, 0, 0]}/> */}
      {/* <RLetter /> */}
      {/* <rectAreaLight
        width={3}
        height={3}
        color={'#84c5a5'}
        intensity={10}
        position={[2, 1, 1]}
        lookAt={[0, 0, 0]}
        penumbra={1}
        castShadow
      /> */}
      </Canvas>
    </div>
  )
}

function Stars(props) {
  const ref = useRef()
  var mat = useRef()
  const [sphere] = useState(() => random.inSphere(new Float32Array(5000), { radius: 1.5 }))
  useFrame((state, delta) => {
    ref.current.rotation.x -= delta / 10
    ref.current.rotation.y -= delta / 15
  })

  // State to track the current and target colors
  const [currentColor] = useState(new THREE.Color(0xffa0e0));
  const [targetColor, setTargetColor] = useState(generateLightColor());
  const transitionSpeed = 0.1; // Adjust this value to control the transition speed

  useFrame(() => {
    // Gradually transition to the target color
    currentColor.lerp(targetColor, transitionSpeed);
    mat.current.color.copy(currentColor);

    // Calculate the distance between currentColor and targetColor
    const rDist = Math.abs(currentColor.r - targetColor.r);
    const gDist = Math.abs(currentColor.g - targetColor.g);
    const bDist = Math.abs(currentColor.b - targetColor.b);

    // If the colors are close enough, pick a new target color
    if (rDist < 0.01 && gDist < 0.01 && bDist < 0.01) {
      // setTargetColor(new THREE.Color(Math.random() * 0xffffff));
      setTargetColor(generateLightColor());
    }
  });

  return (
    <group rotation={[0, 0, Math.PI / 4]}>
      <Points ref={ref} positions={sphere} stride={3} frustumCulled={false} {...props}>
        <PointMaterial ref={mat} transparent size={0.008} sizeAttenuation={true} depthWrite={false} />
      </Points>
    </group>
  )
}
// Function to generate a random light color
function generateLightColor() {
  const r = 0.5 + Math.random() * 0.5;
  const g = 0.5 + Math.random() * 0.5;
  const b = 0.5 + Math.random() * 0.5;
  return new THREE.Color(r, g, b);
}

export function Earth() {
  const [hovered, setHover] = useState(false)
  const earth = React.useRef()
  const [colorMap/*, normalMap*/] = useLoader(TextureLoader, [
    'earth.jpg',
    // 'earthn.png',
  ])

  useFrame(({ clock }) => {
    earth.current.rotation.y = clock.getElapsedTime()
  })

  return (
    <>
    <mesh ref={earth} visible userData={{ test: "hello" }} position={[.65, -.50, -.6]} castShadow
        onPointerOver={(event) => setHover(true)}
        onPointerOut={(event) => setHover(false)}
        >
      <sphereGeometry attach="geometry" args={[hovered?3.7:3.5, 16, 16]} />
      <meshStandardMaterial
        attach="material"
        transparent
        roughness={0.7}
        metalness={0.85}
        map={colorMap}
      />
    </mesh>
    <directionalLight intensity={3} position={[-3, 1, 5]} castShadow></directionalLight>
    </>
  )
}