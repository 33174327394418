import React, { useState } from 'react'
import StarsBack, { Earth } from '../../components/three/StarsBack'
import './Home.css'
import { Link } from 'react-router-dom';
import ProjectsList from '../../components/ProjectsList';
import './ScrollDown.css'
import RLetter from '../../components/three/r';
import { Canvas } from '@react-three/fiber';
import ScrollToTop from '../ScrollToTop';

function Home() {
  const [stars, setStars] = useState(false);
  return (
    <>
    {stars ? <StarsBack/> : null}

    <div style={{width:'100vw', display:'flex', justifyContent:'center'}}>
    <div className="Navbar">
      <img onClick={() => setStars(!stars)} style={{width:'45px', borderRadius:'50%'}} src="Logo192.png" alt="" />
    </div>
    </div>
    
    <div style={{position:'absolute', width:'100px',height:'100px'}}>
    {/* <Canvas>
      <RLetter/>
    </Canvas> */}
    </div>

    {/* <div style={{position:'fixed', bottom:'-50px', right:'-120px'}}>
    <Canvas>
    <Earth/>
    </Canvas>
    </div> */}
    
    {/* <div className='HomePage gradient'>
    <div className="top-container">
      <div className="home-introduction-section">
        <div className="intro-container">
          <div className="intro-greeting">
          Hey!
          <img className='hi-img' height="50px" src="wave.png" alt="" />
          </div>
          <div className="intro-title">
            I am <span style={{color:'#F2436D'}}>Rohit Patidar</span>
          </div>
          <div className="intro-description">
          <Link className='about-btn' to={'about'}>More About >></Link>
          </div>
        </div>

        <div className="intro-image-container">
          <img className='intro-image' src="logo512.png" alt="" draggable={false} />
        </div>

        <a href="#">
            <div id="mouse-scroll">
            <div class="mouse">
            <div class="mouse-in"></div>
            </div>
            <div>
            <span class="down-arrow-1"></span>
            <span class="down-arrow-2"></span>
            <span class="down-arrow-3"></span>
            </div>
            </div>
            </a>

        </div>
      </div> */}

      <div className="Home">
            <div className="top gradient">
            <div className="top-content">
            <div className="introduction-section">
                <div className="intro-title-container">
                <p className="intro-title">I am <br /> <div className='what-i-do'></div> </p>
                <img className='hi-img' height="50px" src="wave.png" alt="" />
                </div>
                <div className="subtitle-container">
                    <div className="intro-subtitle">Hi! I am Rohit Patidar. <br />I am passionated <span style={{color:'#30ce60'}}>Game</span> and <span style={{color:'#00b6ff'}}>Web</span> Developer based in Madhya Pradesh, India. 📍</div>
                    {/* <Link to='/' style={{color: '#41A6FF', fontSize:'16px', fontWeight: '500', textDecoration: 'none'}}>About Me...</Link> */}
                </div>
            </div>
            <div className="intro-image-container" onClick={() => setStars(!stars)}>
                <img className='intro-image' height="300px" src="logo512.png" alt="" draggable={false} />
            </div>
            </div>
            </div>

            <a href="/">
            <div id="mouse-scroll">
            <div class="mouse">
            <div class="mouse-in"></div>
            </div>
            <div>
            <span class="down-arrow-1"></span>
            <span class="down-arrow-2"></span>
            <span class="down-arrow-3"></span>
            </div>
            </div>
            </a>

        </div>


      <ProjectsList/>

    {/* </div> */}
    </>
  )
}

export default Home
