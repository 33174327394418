import React, { useRef, useState } from 'react'

import './ProjectPage.css'

import { useParams } from 'react-router-dom';
import { projects } from '../../data/projects';

function ProjectPage() {
  const { id } = useParams();

  if(projects[id] === undefined) return <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center',  justifyContent: 'center', height:'70vh', color: 'gray', fontWeight: 'bold', fontSize: '20px'}}>
  <img src="/logo192.png" alt="" style={{borderRadius:'50%', height: '80px', marginBottom: '20px'}} />
  {`"${id}"`} does not exist</div>;
  return (
    <div className='ProjectPage'>
      <div className="project-title-bar">
        {projects[id].Title}
      </div>

      <div className="project-description-container" dangerouslySetInnerHTML={{ __html: projects[id].Description }}>
      </div>

      <div className="project-thumbnail-container">
        <img className='project-thumbnail' src={projects[id].Thumbnail} alt="" />
      </div>

      <div className='view-proj-btn-container'>
      <a className='view-proj-btn' target='_blank'
      href={projects[id].DemoUrl !== "" ? projects[id].DemoUrl : ""}
      >View Project</a>
      </div>

    </div>
  )
}

export default ProjectPage
