import { useGLTF } from '@react-three/drei'
import { useLoader } from '@react-three/fiber'
import React, { useEffect, useRef, useState } from 'react'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { TextureLoader } from 'three/src/loaders/TextureLoader'
import * as THREE from 'three';

export default function RLetter(props) {
    const gltf = useLoader(GLTFLoader, '/r.gltf')
    const r = useRef()
    const [colorMap, normalMap] = useLoader(TextureLoader, [
        'r_mat.png',
        'earthn.png',
    ])

    const [hovered, setHover] = useState(false)

    // useFrame(({ clock }) => {
    //     r.current.rotation.y = clock.getElapsedTime()
    // })

    useEffect(() => {
        // Create a material with the loaded textures
        const material = new THREE.MeshStandardMaterial({
            map: colorMap,
            // normalMap: normalMap,
        })

        // Apply the material to all meshes in the GLTF scene
        gltf.scene.traverse((child) => {
            if (child.isMesh) {
                child.material = material
            }
        })
    }, [gltf, colorMap, normalMap])

    return (
        <>
            <primitive 
                ref={r} 
                object={gltf.scene} 
                scale={hovered ? 5 : 4} 
                position={[0,0,0]}
                rotation={[0,.3,0]}
                onPointerOver={(event) => setHover(true)}
                onPointerOut={(event) => setHover(false)}
            />
            <directionalLight intensity={3} position={[-3, 1, 5]} castShadow></directionalLight>
        </>
    );
}

useGLTF.preload('/r.gltf')