import React, { useEffect, useState } from 'react'
import './ProjectsList.css'
import ProjectCard from './ProjectCard'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Link, useParams } from 'react-router-dom';
import { projects } from '../data/projects';

function AllProjectsList(props) {
  const { id } = useParams();
  const [tab, setTab] = useState("- Games -");
  
  const handleTab = (tab) => {
    if (tab === 0) {
      setTab("- Games -");
    } else if (tab === 1) {
      setTab("- Websites -");
    }
  }

  // useEffect(() => {
  //   document.getElementsByClassName("projects-type-title").item(0).innerHTML = tab;
  // }, [tab]);

  return (
    <div className="projects projects-list-gradient">
      <div className="projects-title-container">
      {/* <div className="projects-type-title"></div> */}
            <div className="projects-heading">ALL PROJECTS</div>
      </div>

      
      {/* <Tabs
        selectedTabClassName='projects-tab-selected'
        onSelect={handleTab}
        defaultIndex={id}
        >

        <TabList className="projects-tabs-container">
          <Tab className="projects-tab">Games</Tab>
          <Tab className="projects-tab">Websites</Tab>
        </TabList>

        <TabPanel> */}
          <div className="projects-container">
            {
              projects.map((project, key) => {
                return (
                  <ProjectCard key={key} 
                    Id={project.id}
                    Title={project.Title}
                    Description={project.Description} 
                    Thumbnail={project.Thumbnail}
                  />
                );
              })
            }
          </div>
        {/* </TabPanel>
        <TabPanel> */}
          {/* <div className="projects-container">

            {
              projects.map((project, key) => {
                return (
                  <ProjectCard key={key} 
                    Id={project.id}
                    Title={project.Title}
                    Description={project.Description} 
                    Thumbnail={project.Thumbnail}
                  />
                );
              })
            }
          </div> */}
        {/* </TabPanel>

      </Tabs>
       */}
      
    </div>
  )
}

export default AllProjectsList
