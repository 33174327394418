import React, { useEffect, useState } from 'react'
import './ProjectsList.css'
import ProjectCard from './ProjectCard'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Link } from 'react-router-dom';
import { projects } from '../data/projects';

function ProjectsList() {

  var websites = 0;
  var games = 0;

  const [tab, setTab] = useState("- Games -");
  
  const handleTab = (tab) => {
    if (tab === 0) {
      setTab("- Games -");
    } else if (tab === 1) {
      setTab("- Websites -");
    }
  }

  // useEffect(() => {
  //   document.getElementsByClassName("projects-type-title").item(0).innerHTML = tab;
  // }, [tab]);

  return (
    <div className="projects projects-list-gradient">
      <div className="projects-title-container">
      {/* <div className="projects-type-title"></div> */}
            <div className="projects-heading">PROJECTS</div>
      </div>

      
          <div className="projects-container">
            {
            
              Object.keys(projects).map((key) => {
        const project = projects[key];
        return (
          <ProjectCard key={key} 
                    Id={key}
                    Title={project.Title}
                    MiniDescription={project.MiniDescription} 
                    Thumbnail={project.Thumbnail}
                    Tags={project.type}
                  />
        )})}
              
                  {/* else if (project.type === 'Website') {
                websites += 1;
                return (
                  <ProjectCard key={key} 
                    Id={project.id}
                    Title={project.Title}
                    Description={project.Description} 
                    Thumbnail={project.Thumbnail}
                    Tags={"Website"}
                  />
                );}
              }) */}
  
            
            {/* <div>
              <Link
                style={{color:'black', fontWeight:'600', textDecoration:'none', fontSize:'20px'}}
                to={'/all-projects'}>More...
              </Link>
            </div> */}

          </div>
      
    </div>
  )
}

export default ProjectsList
