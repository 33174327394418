import './App.css';

import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom'
import Home from './pages/home/Home';
import Footer from './components/Footer';
import AllProjectsList from './components/AllProjectsList';
import ScrollToTop from './pages/ScrollToTop';
import ProjectPage from './pages/project/ProjectPage';

function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route path='/' element={<Root/>}>
        <Route index element={<Home/>}/>
        <Route exact path='/all-projects' element={<AllProjectsList/>}/>
        <Route exact path='/project/:id' element={<ProjectPage/>}/>
        <Route exact path='/:id' element={<>s</>}/>
      </Route>
    </Routes>
    <ScrollToTop/>
    </BrowserRouter>
  );
}

function Root() {
  return(
    <>
      <Outlet/>
      <Footer/>
    </>
  )
}

export default App;
